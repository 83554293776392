<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon> GUIA SADT
  </v-btn>
</template>

<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';

const { withCRUDUtils } = UtilsFunc;
export default {
  name: "sadt",
  data: () =>
    withCRUDUtils({
      loading: false,
      orcamento: [],
      procedimentos: [],
      prorrogacao: [],
      medico: "",
      qtd: [],
      item: "",
      quant: "",
      signed: ""
    }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProcedimentos(),
        ]);
        await this.saveData();
        this.qtd = []
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
      }
    },
    async drawHeader(doc) {
      const data = this.orcamento;
      const medicoPaciente = this.medico;
      // Logomarca do Convênio
      try {
        const imageUrl = `https://api.gssma.com.br/media/${data?.convenio_logomarca}`;

        doc.addImage(imageUrl, 5, 5, 22, 12);
      } catch (error) {
        this.$toast.error(
          "Falha ao adicionar a imagem da Logomarca da Empresa. Verifique o Cadastro da Empresa",
          error
        );
      }
      // Carimbo da Empresa
      try {
        const imageUrl = `https://api.gssma.com.br/media/${data?.empresa_carimbo}`;
        doc.addImage(imageUrl, 228, 195, 22, 12);
      } catch (error) {
        this.$toast.error(
          "Falha ao adicionar a imagem do Carimbo da empresa. Verifique o Cadastro da Empresa",
          error
        );
      }
      // Carimbo do Médico do paciente
      try {
        let imageUrl = `${medicoPaciente?.carimbo}`;
        imageUrl = imageUrl.replace("http", "https");
        doc.addImage(imageUrl, 250, 57, 22, 12);
      } catch (error) {
        this.$toast.error(
          "Falha ao adicionar a imagem do Carimbo do Médico Responsável. Verifique o Cadastro do Médico",
          error
        );
      }
      // Assinatura do Paciente Autorizando a guia
      if (this.sign) { // Verifica se a assinatura existe
        try {
          let imageUrl = `${this.sign}`;
          const params = this.sign.split("://");
          if (params[0] === "http") {
            imageUrl = imageUrl.replace("http", "https");
          }
          doc.addImage(imageUrl, 150, 199, 22, 12);
        } catch (error) {
          this.$toast.error(
            "Falha ao adicionar a assinatura. Verifique a assinatura do paciente.",
            error
          );
        }
      }

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        "GUIA DE SERVIÇO PROFISSIONAL / SERVIÇO AUXILIAR DE DIAGNÓSTICO E TERAPIA - SP/SADT",
        140,
        15,
        { align: "center" }
      );
      doc.setFontSize(7);
      doc.text("2 - Nº Guia do Prestador:", 250, 15, { align: "center" });
      doc.text(`${data.nr_guia_prestador ?? ''}`, 271, 15, { align: "center" });
    },
    async basePDF(doc, pageNumber) {
      doc.setPage(pageNumber);

      const margin = 2;

      // Calcule as dimensões da borda interna
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const width = pageWidth - 2 * margin;
      const height = pageHeight - 2 * margin;

      await this.drawHeader(doc);

      // Desenha a borda interna
      doc.setDrawColor(0); // Cor da borda preta, altere conforme necessário
      doc.setLineWidth(0.5); // Espessura da linha, altere conforme necessário
      doc.rect(margin, margin, width, height);
      this.BORDER_RADIUS = 1;

      try {
        await Promise.all([
          this.baseCabecalho(doc),
          this.baseBeneficiario(doc, margin, pageWidth),
          this.baseSolicitante(doc, margin, pageWidth),
          this.baseSolicitacao(doc, margin, pageWidth),
          this.baseContratadoExecutante(doc, margin, pageWidth),
          this.baseAtendimento(doc, margin, pageWidth),
          this.baseExecucao(doc, margin, pageWidth),
          this.baseProfissionalExecutante(doc, margin, pageWidth),
          this.baseObservacao(doc, margin, pageWidth)
        ]);
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados (L-151):", error);
      }
    },
    async baseCabecalho(doc) {
      this.xPosition = 3; // posição X onde o texto começa
      this.yPosition = 20; // posição Y onde o texto começa
      this.rectangleWidth = 28; // largura do retângulo
      this.rectangleHeight = 8; // altura do retângulo
      doc.setDrawColor(0); // Cor da borda preta
      doc.setLineWidth(0.1); // Espessura da linha
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      // Adiciona o texto centralizado no retângulo
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      this.textOffset = (this.rectangleHeight - 6) / 2; // Centraliza o texto verticalmente dentro do retângulo
      doc.text(
        "1 - Registro ANS",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      ); // Ajusta o segundo valor de Y conforme necessário para centralizar

      this.xPosition = 32; // posição X(largura) onde o texto começa
      this.yPosition = 20; //posição Y(altura) onde o texto começa
      this.rectangleWidth = 63; // largura do retângulo
      doc.setDrawColor(0); // Cor da borda preta
      doc.setLineWidth(0.1); // Espessura da linha
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "3 - Número da Guia Principal",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 96;
      this.yPosition = 20;
      this.rectangleWidth = 35;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "4 - Data da Autorização",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 132;
      this.yPosition = 20;
      this.rectangleWidth = 55;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text("5 - Senha", this.xPosition + 1, this.yPosition + this.textOffset + 1);

      this.xPosition = 188;
      this.yPosition = 20;
      this.rectangleWidth = 45;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "6 - Data da Validade da Senha",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 234;
      this.yPosition = 20;
      this.rectangleWidth = 60;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "7 - Número da Guia Atribuida pela Operadora",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );
    },
    async baseBeneficiario(doc, margin, pageWidth) {
      // Dados do Beneficiário
      this.xPosition = margin + 1;
      this.yPosition = 29;
      this.rectangleWidth = pageWidth - 3 * margin;
      this.rectangleHeight = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;
      doc.text("Dados do Beneficiário", this.xPosition + 2, this.textOffset);

      this.xPosition = 3;
      this.yPosition = 35;
      this.rectangleWidth = 55;
      this.rectangleHeight = 8;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.text(
        "8 - Número da Carteira",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 59;
      this.yPosition = 35;
      this.rectangleWidth = 50;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "9 - Validade da Carteira",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 110;
      this.yPosition = 35;
      this.rectangleWidth = 92;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text("10 - Nome", this.xPosition + 1, this.yPosition + this.textOffset + 1);

      this.xPosition = 203;
      this.yPosition = 35;
      this.rectangleWidth = 50;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "11 - Carteira Nacional de Saúde",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 254;
      this.yPosition = 35;
      this.rectangleWidth = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "12 - Atendimento a RN",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );
      doc.text("N", this.xPosition + 1, this.yPosition + this.textOffset + 6);
    },
    async baseSolicitante(doc, margin, pageWidth) {
      // Dados Solicitante
      this.xPosition = margin + 1; // Início do fundo, geralmente a margem esquerda do documento
      this.yPosition = 44; // Posição Y onde o fundo começa
      this.rectangleWidth = pageWidth - 3 * margin; // A largura do fundo é a largura da página menos as margens dos dois lados
      this.rectangleHeight = 5; // Altura do fundo, ajuste conforme necessário
      doc.setFillColor(173, 216, 230); // Cor azul claro
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Defina a cor do texto para preto
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;
      doc.text("Dados do Solicitante", this.xPosition + 2, this.textOffset);

      this.xPosition = 3;
      this.yPosition = 50;
      this.rectangleWidth = 55;
      this.rectangleHeight = 8;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.text(
        "13 - Código da Operadora",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 59;
      this.yPosition = 50;
      this.rectangleWidth = 235;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "14 - Nome do Contratado",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 3;
      this.yPosition = 59;
      this.rectangleWidth = 71;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "15 - Nome do Profissional Solicitante",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 75;
      this.yPosition = 59;
      this.rectangleWidth = 32;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "16 - Conselho Profissional",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 108;
      this.yPosition = 59;
      this.rectangleWidth = 32;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "17 - Número no Conselho",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 141;
      this.yPosition = 59;
      this.rectangleWidth = 30;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text("18 - UF", this.xPosition + 1, this.yPosition + this.textOffset + 1);

      this.xPosition = 172;
      this.yPosition = 59;
      this.rectangleWidth = 30;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "19 - Código CBO",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 203;
      this.yPosition = 59;
      this.rectangleWidth = 91;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "20 - Assinatura do Profissional Solicitante",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );
    },
    async baseSolicitacao(doc, margin, pageWidth) {
      // Dados da Solicitação / Procedimento ou Itens Assistenciais Solicitados
      this.xPosition = margin + 1; // Início do fundo, geralmente a margem esquerda do documento
      this.yPosition = 68; // Posição Y onde o fundo começa
      this.rectangleWidth = pageWidth - 3 * margin; // A largura do fundo é a largura da página menos as margens dos dois lados
      this.rectangleHeight = 5; // Altura do fundo, ajuste conforme necessário
      doc.setFillColor(173, 216, 230); // Cor azul claro
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS,
        "F"
      ); // 'F' para preenchimento
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Defina a cor do texto para preto
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;
      doc.text(
        "Dados da Solicitação / Procedimento ou Itens Assistenciais Solicitados",
        this.xPosition + 2,
        this.textOffset
      );

      this.xPosition = 3;
      this.yPosition = 74;
      this.rectangleWidth = 40;
      this.rectangleHeight = 8;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.text(
        "21 - Caráter de Atendimento",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 44;
      this.yPosition = 74;
      this.rectangleWidth = 50;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "22 - Data da Solicitação",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 95;
      this.yPosition = 74;
      this.rectangleWidth = 199;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "23 - Indicação Clínica",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 3;
      this.yPosition = 83;
      this.rectangleWidth = 291;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        13,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text("24 - Tabela", this.xPosition + 4, this.yPosition + this.textOffset + 1);

      doc.text(
        "25 - Código do Procedimento",
        this.xPosition + 22,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "26 - Descrição",
        this.xPosition + 60,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "27 - Qtd. Solicitada",
        this.xPosition + 240,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "28 - Qtd. Autorizada",
        this.xPosition + 265,
        this.yPosition + this.textOffset + 1
      );
    },
    async baseContratadoExecutante(doc, margin, pageWidth) {
      // Dados do Contratado Executante
      this.xPosition = margin + 1;
      this.yPosition = 97;
      this.rectangleWidth = pageWidth - 3 * margin;
      this.rectangleHeight = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;
      doc.text(
        "Dados do Contratado Executante",
        this.xPosition + 2,
        this.textOffset
      );

      this.xPosition = 3;
      this.yPosition = 103;
      this.rectangleWidth = 45;
      this.rectangleHeight = 8;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.text(
        "30 - Código na Operadora",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 49;
      this.yPosition = 103;
      this.rectangleWidth = 214;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "31 - Nome do Contratado",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 264;
      this.yPosition = 103;
      this.rectangleWidth = 30;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "40 - Código CNES",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );
    },
    async baseAtendimento(doc, margin, pageWidth) {
      // Dados do Atendimento
      this.xPosition = margin + 1;
      this.yPosition = 112;
      this.rectangleWidth = pageWidth - 3 * margin;
      this.rectangleHeight = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;
      doc.text("Dados do Atendimento", this.xPosition + 2, this.textOffset);

      this.xPosition = 3;
      this.yPosition = 118;
      this.rectangleWidth = 30;
      this.rectangleHeight = 8;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.text(
        "32 - Tipo de Atendimento",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 34;
      this.yPosition = 118;
      this.rectangleWidth = 80;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "33 - Indicação de Acidente (acidente ou doença relacionada)",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 115;
      this.yPosition = 118;
      this.rectangleWidth = 30;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "34 - Tipo de Consulta",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 146;
      this.yPosition = 118;
      this.rectangleWidth = 70;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "35 - Motivo de Encerramento do Atendimento",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );
    },
    async baseExecucao(doc, margin, pageWidth) {
      // Dados da Execução / Procedimentos e Exames Realizados
      this.xPosition = margin + 1;
      this.yPosition = 127;
      this.rectangleWidth = pageWidth - 3 * margin;
      this.rectangleHeight = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;
      doc.text(
        "Dados da Execução / Procedimentos e Exames Realizados",
        this.xPosition + 2,
        this.textOffset
      );

      this.xPosition = 3;
      this.yPosition = 133;
      this.rectangleWidth = 291;
      this.rectangleHeight = 8;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        14,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.text("36 - Data", this.xPosition + 3, this.yPosition + this.textOffset + 1);
      doc.text(
        "37 - Hora Inicial",
        this.xPosition + 22,
        this.yPosition + this.textOffset + 1
      );
      doc.text(``, this.xPosition + 22, this.yPosition + this.textOffset + 6);
      doc.text(
        "38 - Hora Final",
        this.xPosition + 40,
        this.yPosition + this.textOffset + 1
      );
      doc.text(``, this.xPosition + 40, this.yPosition + this.textOffset + 6);
      doc.text(
        "39 - Tabela",
        this.xPosition + 60,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "40 - Código Procedimento",
        this.xPosition + 75,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "41 - Descrição",
        this.xPosition + 110,
        this.yPosition + this.textOffset + 1
      );

      doc.text("42 - Qtde", this.xPosition + 185, this.yPosition + this.textOffset + 1);

      doc.text("43 - Via", this.xPosition + 205, this.yPosition + this.textOffset + 1);

      doc.text(
        "44 - Fator Red./Acresc.",
        this.xPosition + 220,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "45 - Valor Unitário",
        this.xPosition + 248,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "46 - Valor Total",
        this.xPosition + 270,
        this.yPosition + this.textOffset + 1
      );
    },
    async baseProfissionalExecutante(doc, margin, pageWidth) {
      // Identificação do(s) Profissional(is) Executante(s)
      this.xPosition = margin + 1;
      this.yPosition = 148;
      this.rectangleWidth = pageWidth - 3 * margin;
      this.rectangleHeight = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;
      doc.text(
        "Identificação do(s) Profissional(is) Executante(s)",
        this.xPosition + 2,
        this.textOffset
      );

      this.xPosition = 3;
      this.yPosition = 154;
      this.rectangleWidth = 291;
      this.rectangleHeight = 8;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        12,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.text(
        "48 - Seq.Ref",
        this.xPosition + 2,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "49 - Grau Part",
        this.xPosition + 20,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "50 - Código na Operadora / CPF",
        this.xPosition + 40,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "51 - Nome do Profissional",
        this.xPosition + 80,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "52 - Conselho profissional",
        this.xPosition + 185,
        this.yPosition + this.textOffset + 1
      );

      doc.text(
        "53 - Número no Conselho",
        this.xPosition + 220,
        this.yPosition + this.textOffset + 1
      );

      doc.text("54 - UF", this.xPosition + 250, this.yPosition + this.textOffset + 1);

      doc.text(
        "55 - Código CBO",
        this.xPosition + 270,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 3;
      this.yPosition = 167;
      this.rectangleWidth = 291;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        16,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "56 - Data Realização Procedimento em Série           57 - Assinatura do Beneficiário ou Responsável]",
        this.xPosition + 2,
        this.yPosition + this.textOffset + 1
      );
    },
    async baseObservacao(doc, margin, pageWidth) {
      // Campo de Observação
      this.xPosition = margin + 1;
      this.yPosition = 184;
      this.rectangleWidth = pageWidth - 3 * margin;
      this.rectangleHeight = 5;
      doc.setFillColor(173, 216, 230);
      doc.setDrawColor(0);
      doc.setLineWidth(0.2);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;
      doc.text(
        "58 - Observação / Justificativa:  " + "",
        this.xPosition + 2,
        this.textOffset
      );

      // Parte de Resumo Valores
      this.xPosition = margin + 1;
      this.yPosition = 190;
      this.rectangleWidth = 40;
      this.rectangleHeight = 8;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.text(
        "59 - Total Procedimentos",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 44;
      this.yPosition = 190;
      this.rectangleWidth = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "60 - Total Taxas e Alugueis",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 85;
      this.yPosition = 190;
      this.rectangleWidth = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "61 - Total Materiais",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 126;
      this.yPosition = 190;
      this.rectangleWidth = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "62 - Total de OPME",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 167;
      this.yPosition = 190;
      this.rectangleWidth = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "63 - Total de Medicamentos",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 208;
      this.yPosition = 190;
      this.rectangleWidth = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "64 - Total de Gases Medicinais",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 249;
      this.yPosition = 190;
      this.rectangleWidth = 45;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "65 - Total Geral da Guia",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = margin + 1;
      this.yPosition = 199;
      this.rectangleWidth = 90;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "66 - Assinatura do Responsável pela Autorização",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 94;
      this.yPosition = 199;
      this.rectangleWidth = 100;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "67 - Assinatura do Beneficiário ou Responsável",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );

      this.xPosition = 195;
      this.yPosition = 199;
      this.rectangleWidth = 99;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        this.rectangleHeight,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.text(
        "68 - Assinatura do Contratado",
        this.xPosition + 1,
        this.yPosition + this.textOffset + 1
      );
    },
    async mountPDFData(doc, item) {
      try {
        await Promise.all([
          this.dadosCabecalho(doc),
          this.dadosDoBeneficiario(doc),
          this.dadosSolicitante(doc),
          this.dadosSolicitacao(doc, item),
          this.dadosContratadoExecutante(doc),
          this.dadosAtendimento(doc),
          this.dadosExecucao(doc, item),
          this.profissionalExecutante(doc, item)
        ]);
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados (L-241):", error);
      }
    },
    async saveData() {
      if (this.qtd.length > 0) {
        this.$toast.success(
          `Será(ão) gerado(s) ${this.qtd.length} PDF(s).Aguarde, por favor.`
        );
      } else {
        this.$toast.info(
          `Não há guias SADT para serem geradas.`
        );
        this.loading = false;
        return;
      }

      for (const procedimento of this.qtd) {
        this.quantidade = procedimento.qtd;
        // Encontra o primeiro procedimento com o procedimento_id correspondente e que tenha um nome de profissional.
        const item = this.procedimentos.find(
          (p) => p.procedimento.procedimento_id === procedimento.procedimento_id
        );

        this.item = item;

        if (item) {

          const doc = new jsPDF({
            orientation: "landscape",
            format: "a4",
            compress: true,
          });

          // Define a posição e o tamanho do retângulo ao redor do campo "1 - Registro ANS"
          this.xPosition = 3; // posição X onde o texto começa
          this.yPosition = 20; // posição Y onde o texto começa
          this.rectangleWidth = 28; // largura do retângulo
          this.rectangleHeight = 8; // altura do retângulo
          this.BORDER_RADIUS = 1;

          await Promise.all([
            this.mountPDFData(doc, item),
            this.assinaturaPacientes(doc, item)
          ])
          this.doc = doc;
        }

        const numberOfPages = this.doc.internal.getNumberOfPages();
        // this.totalValue = this.sumTotalAmountPerExpense(this.combinedData);

        for (let i = 1; i <= numberOfPages; i++) {
          // Chama basePDF para cada página
          await this.basePDF(this.doc, i);
          // await this.totalSumSadt(this.doc, i, 4, 191);
        }

        try {
          const paciente = this.orcamento.paciente;
          const formattedName = paciente.replace(/\s+/g, '_');
          const procedure = item.procedimento.procedimento;
          const formattedProcedure = procedure.replace(/\s+/g, '_');
          const convenio = this.orcamento.convenio;
          const data_inicio = this.orcamento && this.orcamento?.data_inicio;
          const data_fim = this.orcamento && this.orcamento?.data_fim;
          const period = data_inicio && data_fim ? `${UtilsPDF.formatDateToFilename(data_inicio)}_ate_${UtilsPDF.formatDateToFilename(data_fim)}` : '';

          const filename = `Guia_SADT_${formattedProcedure}_${formattedName}_${convenio}_Período_${period}.pdf`;

          const pdfBuffer = this.doc.output('arraybuffer');
          const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
          const formData = new FormData();
          formData.append("file", pdfBlob, filename);
          formData.append("filename", filename);


          const { data: signedForm } = await api.post('multi_empresas/sign-pdf-sadt/', formData);
          const signedPdfUrl = signedForm.path;
          this.download(signedPdfUrl, filename);
        } catch (error) {
          console.error("Erro ao salvar o arquivo:", error);
          this.$toast.error("Erro ao salvar o arquivo: " + error.message);
        }
      }
      if (this.qtd.length > 0) this.$toast.success("Download do(s) arquivo(s) realizado(s) com sucesso!");
    },
    async dadosCabecalho(doc) {
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      this.xPosition = 3; // posição X onde o texto começa
      this.yPosition = 20; // posição Y onde o texto começa
      this.rectangleWidth = 28; // largura do retângulo
      this.rectangleHeight = 8; // altura do retângulo
      this.textOffset = (this.rectangleHeight - 6) / 2; // Centraliza o texto verticalmente dentro do retângulo

      // 1 - Registro ANS
      doc.text(
        `${this.orcamento.convenio_ans ? this.orcamento.convenio_ans : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      ); // Ajusta o segundo valor de Y conforme necessário para centralizar

      this.xPosition = 32; // posição X(largura) onde o texto começa
      this.yPosition = 20; //posição Y(altura) onde o texto começa
      this.rectangleWidth = 63; // largura do retângulo
      this.textOffset = (this.rectangleHeight - 6) / 2;

      doc.text(
        `${this.orcamento.nr_guia_operador ? this.orcamento.nr_guia_operador : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 96;
      this.yPosition = 20;
      this.rectangleWidth = 35;
      this.textOffset = (this.rectangleHeight - 6) / 2;

      // 4 - Data Autorização
      doc.text(
        `${UtilsPDF.formatDate(this.orcamento?.senha_data_inicio)}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 132;
      this.rectangleWidth = 55;
      this.textOffset = (this.rectangleHeight - 6) / 2;

      // 5 - Senha
      doc.text(
        `${this.orcamento.senha ? this.orcamento.senha : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 188;
      this.rectangleWidth = 45;
      this.textOffset = (this.rectangleHeight - 6) / 2;

      // 6 - Data da Validade da Senha
      doc.text(
        `${UtilsPDF.formatDate(this.orcamento?.senha_data_validade)}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 234;
      this.rectangleWidth = 60;
      this.textOffset = (this.rectangleHeight - 6) / 2;

      // 7 - Número da Guia Atribuida pela Operadora
      doc.text(
        `${this.orcamento.nr_guia_operador ? this.orcamento.nr_guia_operador : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );
    },
    async dadosDoBeneficiario(doc) {
      // Dados do Beneficiário

      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      this.xPosition = 3;
      this.yPosition = 35;
      this.rectangleWidth = 55;
      this.rectangleHeight = 8;
      this.textOffset = (this.rectangleHeight - 6) / 2;

      doc.text(
        `${this.orcamento.nr_carteirinha ? this.orcamento.nr_carteirinha : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 59;
      this.yPosition = 35;
      this.rectangleWidth = 50;

      this.textOffset = (this.rectangleHeight - 6) / 2;

      // 9 - Validade da Carteira
      doc.text(
        `${UtilsPDF.formatDate(this.orcamento?.validade)}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 110;
      this.yPosition = 35;
      this.rectangleWidth = 92;

      this.textOffset = (this.rectangleHeight - 6) / 2;

      // 10 - Nome
      doc.text(
        `${this.orcamento.paciente ? this.orcamento.paciente : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 203;
      this.yPosition = 35;
      this.rectangleWidth = 50;

      this.textOffset = (this.rectangleHeight - 6) / 2;

      // 11 - Carteira Nacional de Saúde
      doc.text(
        `${this.orcamento.paciente_carteira_sus ? this.orcamento.paciente_carteira_sus : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );
    },
    async dadosSolicitante(doc) {
      // Dados Solicitante
      this.yPosition = 44; // Posição Y onde o fundo começa
      this.rectangleHeight = 5; // Altura do fundo, ajuste conforme necessário

      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.setTextColor(0, 0, 0);
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;

      this.xPosition = 3;
      this.yPosition = 50;
      this.rectangleWidth = 55;
      this.rectangleHeight = 8;

      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");

      // 13 - Código da Operadora
      doc.text(
        `${this.orcamento.convenio_codigo ? this.orcamento.convenio_codigo : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 59;
      this.yPosition = 50;
      this.rectangleWidth = 235;

      // 14 - Nome do Contratado
      doc.text(
        `${this.orcamento.empresa_nome ? this.orcamento.empresa_nome : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 3;
      this.yPosition = 59;
      this.rectangleWidth = 71;

      // 15 - Nome do Profissional Solicitante
      doc.text(
        `${this.medico?.first_name
          ? this.medico?.first_name + " " + this.medico?.last_name
          : ""
        }`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 75;
      this.yPosition = 59;
      this.rectangleWidth = 32;

      doc.text(
        `${this.medico.conselho ? this.medico.conselho.nome : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 108;
      this.yPosition = 59;
      this.rectangleWidth = 32;

      doc.text(
        `${this.medico.nconselho ? this.medico.nconselho : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 141;
      this.yPosition = 59;
      this.rectangleWidth = 30;

      doc.text(
        `${this.medico.uf_inscricao_conselho
          ? this.medico.uf_inscricao_conselho.sigla
          : ""
        }`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 172;
      this.yPosition = 59;
      this.rectangleWidth = 30;

      doc.text(
        `${this.medico.especialidade
          ? this.medico.especialidade.cbo
          : ""
        }`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 203;
      this.yPosition = 59;
      this.rectangleWidth = 91;
      doc.text("", this.xPosition + 1, this.yPosition + this.textOffset + 6);
    },
    async dadosSolicitacao(doc, item) {
      // Dados da Solicitação / Procedimento ou Itens Assistenciais Solicitados

      this.xPosition = 3;
      this.yPosition = 74;
      this.rectangleWidth = 40;
      this.rectangleHeight = 5;
      this.textOffset = (this.rectangleHeight - 6) / 2;

      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.text("1", this.xPosition + 1, this.yPosition + this.textOffset + 6);

      this.xPosition = 44;
      this.yPosition = 74;
      this.rectangleWidth = 50;

      doc.setFont("helvetica", "normal");

      // 22- Data da Solicitação
      doc.text(
        `${UtilsPDF.formatDate(this.orcamento?.senha_data_inicio)}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 95;
      this.yPosition = 74;
      this.rectangleWidth = 199;

      // 23 - Indicação Clínica
      doc.text(
        `${this.orcamento?.cid}` + ` -- ` + `${this.orcamento?.cid_descricao}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 3;
      this.yPosition = 83;
      this.rectangleWidth = 291;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      // 24 - Tabela
      doc.text("1º", this.xPosition + 1, this.yPosition + this.textOffset + 6);
      doc.text(
        `${item && item?.procedimento?.tabela_tabela ? item?.procedimento?.tabela_tabela : ''}`,
        this.xPosition + 4,
        this.yPosition + this.textOffset + 6
      );

      // 25 - Código do Procedimento
      doc.text(
        `${item && item?.procedimento?.codigo ? item?.procedimento?.codigo : ''}`,
        this.xPosition + 22,
        this.yPosition + this.textOffset + 6
      );

      // 26 - Descrição
      const nomeProcedimento = (
        item?.procedimento?.nomecomercial &&
        item?.procedimento?.nomecomercial.trim() !== "") ?
        item?.procedimento?.nomecomercial :
        item.procedimento?.procedimento;

      doc.text(
        `${nomeProcedimento}`,
        this.xPosition + 60,
        this.yPosition + this.textOffset + 6
      );

      const qtd = this.quantidade;

      // 27 - Qtd. Solicitada
      doc.text(
        `${qtd ? qtd : ""}`,
        this.xPosition + 240,
        this.yPosition + this.textOffset + 6
      );

      // 28 - Qtd. Autorizada
      doc.text(
        `${qtd ? qtd : ""}`,
        this.xPosition + 265,
        this.yPosition + this.textOffset + 6
      );
    },
    async dadosContratadoExecutante(doc) {
      // Dados do Contratado Executante
      this.yPosition = 97;
      this.rectangleHeight = 5;

      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.setTextColor(0, 0, 0);
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;

      this.xPosition = 3;
      this.yPosition = 103;
      this.rectangleWidth = 45;

      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");

      doc.text(
        `${this.orcamento.convenio_codigo ? this.orcamento.convenio_codigo : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 49;
      this.yPosition = 103;
      this.rectangleWidth = 214;

      this.textOffset = (this.rectangleHeight - 6) / 2;

      doc.text(
        `${this.orcamento.empresa_nome ? this.orcamento.empresa_nome : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );

      this.xPosition = 264;
      this.yPosition = 103;
      this.rectangleWidth = 30;

      this.textOffset = (this.rectangleHeight - 6) / 2;

      doc.text(
        `${this.orcamento.empresa_cnes ? this.orcamento.empresa_cnes : ""}`,
        this.xPosition + 1,
        this.yPosition + this.textOffset + 6
      );
    },
    async dadosAtendimento(doc) {
      // Dados do Atendimento
      this.xPosition = 3;
      this.yPosition = 118;
      this.rectangleHeight = 5;
      this.rectangleWidth = 30;
      this.textOffset = (this.rectangleHeight - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);

      // 32 - Tipo de Atendimento
      doc.text("6", this.xPosition + 1, this.yPosition + this.textOffset + 6);

      this.xPosition = 34;
      this.yPosition = 118;
      this.rectangleWidth = 80;

      this.textOffset = (this.rectangleHeight - 6) / 2;

      // 33 - Indicação de Acidente
      doc.text("9", this.xPosition + 1, this.yPosition + this.textOffset + 6);

      this.xPosition = 115;
      this.yPosition = 118;
      this.rectangleWidth = 30;

      this.textOffset = (this.rectangleHeight - 6) / 2;

      doc.text("", this.xPosition + 1, this.yPosition + this.textOffset + 6);

      this.xPosition = 146;
      this.yPosition = 118;
      this.rectangleWidth = 70;

      this.textOffset = (this.rectangleHeight - 6) / 2;

      doc.text("", this.xPosition + 1, this.yPosition + this.textOffset + 6);

    },
    async dadosExecucao(doc, i) {
      // Dados da Execução / Procedimentos e Exames Realizados
      const grouped = this.procedimentos.reduce((acc, item) => {
        const id = item?.procedimento?.id;
        const fatorRed = (item?.desconto || item?.desconto_equipamento || item?.desconto_taxa || item?.desconto_pacote)
          ? -(item?.desconto || item?.desconto_equipamento || item?.desconto_taxa || item?.desconto_pacote).toFixed(2)
          : (item?.acrescimo || item?.acrescimo_equipamento || item?.acrescimo_taxa || item?.acrescimo_pacote)
            ? (item?.acrescimo || item?.acrescimo_equipamento || item?.acrescimo_taxa || item?.acrescimo_pacote).toFixed(2)
            : '1.00';
        const key = `${id}_${fatorRed}`;

        if (!acc[key]) {
          acc[key] = { ...item, quantidade: 0, fatorRed };
        }
        acc[key].quantidade += item?.quantidade || 1;
        return acc;
      }, {});

      // Obtém os procedimentos com o mesmo ID do procedimento passado
      const procedimentoId = i.procedimento.id;
      const filteredItems = Object.values(grouped).filter(item => item.procedimento.id === procedimentoId);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.setTextColor(0, 0, 0);
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;

      this.xPosition = 3;
      this.yPosition = 133;
      this.rectangleWidth = 291;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        this.xPosition,
        this.yPosition,
        this.rectangleWidth,
        14,
        this.BORDER_RADIUS,
        this.BORDER_RADIUS
      );

      this.xPosition = 3;
      this.yPosition = 129;
      this.rectangleHeight = 5;
      this.textOffset = this.yPosition + this.rectangleHeight / 2 + 0.5;

      this.filteredItems = filteredItems
      filteredItems.forEach((item, index) => {
        this.yPosition += 3.5;
        this.textOffset = (this.rectangleHeight - 6) / 2;
        doc.setFont("helvetica", "normal");
        doc.text(`${index + 1}º`, this.xPosition + 1, this.yPosition + this.textOffset + 6);

        // 40 - Código Procedimento
        doc.text(
          `${item?.procedimento?.codigo || ''}`,
          this.xPosition + 75,
          this.yPosition + this.textOffset + 6
        );

        // 41 - Descrição
        const nomeProcedimento = (
          item?.procedimento?.nomecomercial &&
          item?.procedimento?.nomecomercial.trim() !== "") ?
          item?.procedimento?.nomecomercial :
          item.procedimento?.procedimento;

        doc.text(
          `${nomeProcedimento}`,
          this.xPosition + 110,
          this.yPosition + this.textOffset + 6
        );

        // 44 - Fator Red./Acresc
        // const formattedFatorRed = item.fatorRed;

        doc.text(
          // `${formattedFatorRed}`,
          '', 
          this.xPosition + 220,
          this.yPosition + this.textOffset + 6
        );

        // 45 - Valor Unitário
        doc.text(
          // `${UtilsPDF.formatToBRL2(parseFloat(item.valor_cobranca_final))}`,
          '', 
          this.xPosition + 248,
          this.yPosition + this.textOffset + 6
        );

        // 46 - Valor Total
        doc.text(
          // `${UtilsPDF.formatToBRL2(parseFloat(item.valor_cobranca_final * item.quantidade))}`,
          '',
          this.xPosition + 270,
          this.yPosition + this.textOffset + 6
        );
      });
    },
    async profissionalExecutante(doc, item) {
      // Identificação do(s) Profissional(is) Executante(s)

      this.xPosition = 3;
      this.yPosition = 154;
      this.rectangleWidth = 291;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);

      this.textOffset = (this.rectangleHeight - 6) / 2;

      doc.text("1º", this.xPosition + 1, this.yPosition + this.textOffset + 6);

      // 50 - Código na Operadora / CPF
      doc.text(
        `${item && item?.profissional?.cpf ? item?.profissional?.cpf : ""}`,
        this.xPosition + 40,
        this.yPosition + this.textOffset + 6
      );

      // 51 - Nome do Profissional
      doc.text(
        `${item && item?.profissional?.nomecompleto ? item?.profissional?.nomecompleto : ""}`,
        this.xPosition + 80,
        this.yPosition + this.textOffset + 6
      );

      // 52 - Conselho Profissional
      doc.text(
        `${item && item?.profissional?.conselho.nome ? item?.profissional?.conselho.nome : ""}`,
        this.xPosition + 185,
        this.yPosition + this.textOffset + 6
      );

      // 53 - Número no Conselho
      doc.text(
        `${item && item?.profissional?.nconselho ? item?.profissional?.nconselho : ""}`,
        this.xPosition + 220,
        this.yPosition + this.textOffset + 6
      );

      // 54 - UF
      doc.text(
        `${item && item?.profissional?.uf_inscricao_conselho?.sigla ? item?.profissional?.uf_inscricao_conselho?.sigla : ""}`,
        this.xPosition + 250,
        this.yPosition + this.textOffset + 6
      );

      // 55 - Código CBO
      doc.text(
        `${item && item?.especialidade?.cbo ? item?.especialidade?.cbo : ""}`,
        this.xPosition + 270,
        this.yPosition + this.textOffset + 6
      );
    },
    async assinaturaPacientes(doc, item) {
      // Assinaturas dos Pacientes
      doc.setFontSize(7);
      doc.setFont("helvetica", "normal");
      const filteredData = this.procedimentos.filter(
        (p) => p.procedimento?.procedimento === item?.procedimento?.procedimento && p?.assinatura && p?.timestamp
      );

      let counter = 0;
      this.sign = filteredData.length > 0 ? filteredData[0].assinatura : '';

       // 42 - Qtde
      this.xPosition = 188;
      this.yPosition = 134;
      this.filteredItems.forEach(item => {
        // 42 - Qtde
        this.yPosition += 3.5;
        const qtd = item && item?.quantidade;

        doc.text(
          `${qtd ? qtd : ""}`,
          this.xPosition,
          this.yPosition + 0.5
        );
      });

      this.xPosition = 5;
      this.yPosition = 173;
      this.rectangleWidth = 291;
      let page = 1; // Controlar o número da página

      for (let i = 0; i < filteredData.length; i++) {
        const procedimento = filteredData[i];

        if (procedimento && procedimento?.assinatura) {
          counter++;
        }

        this.signed = counter;
        let index = i + 1;
        let pageIndex = index - (page - 1) * 10; // Index relativo à página atual

        if (pageIndex > 10) {
          doc.addPage();
          page++;
          pageIndex = 1;

          await this.mountPDFData(doc, item);

          this.xPosition = 188;
          this.yPosition = 134;

          this.filteredItems.forEach(item => {
            // 42 - Qtde
            this.yPosition += 3.5;
            const qtd = item && item?.quantidade;

            doc.text(
              `${qtd ? qtd : ""}`,
              this.xPosition,
              this.yPosition + 0.5
            );
          });

          this.xPosition = 5;
          this.yPosition = 173;
        } else {
          // Aplica o recuo a partir do terceiro item em diante na página
          if (pageIndex > 2 && index % 2 === 1) {
            this.xPosition += 55;
          } else if (pageIndex <= 2) {
            this.xPosition = 5; // Primeiro e segundo item sem recuo
          }
          this.yPosition = pageIndex % 2 === 1 ? 173 : 180;
        }

        doc.text(
          `${index}ª - ${UtilsPDF.formatDate(procedimento?.timestamp)}`,
          this.xPosition,
          this.yPosition
        );

        try {
          let imageUrl = `${procedimento?.assinatura}`;
          const params = procedimento?.assinatura.split("://");
          if (params[0] === "http") {
            imageUrl = imageUrl.replace("http", "https");
          }
          doc.addImage(
            imageUrl,
            this.xPosition + 21,
            this.yPosition - 3,
            30,
            5
          );
        } catch (error) {
          this.$toast.error(
            "Falha ao adicionar a assinatura. Verifique a assinatura do paciente.",
            error
          );
        }
      }
    },
    async getOrcamento() {
      try {
        const { data } = await api.get(
          `atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`
        );
        this.orcamento = data[0];
        if (
          this.orcamento &&
          (this.orcamento.paciente_medico_id ||
            this.orcamento.empresa_medico_responsavel_id)
        ) {
          await this.getMedico();
        }
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do relatório:", error);
        throw error;
      }
    },
    async getMedico() {
      try {
        const idMedico =
          this.orcamento.paciente_medico_id ||
          this.orcamento.empresa_medico_responsavel_id;
        const { data } = await api.get(`contas/usuario/${idMedico}/`);
        this.medico = data;
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do médico:", error);
      }
    },
    async getProcedimentos() {
      try {
        const { data } = await api.get(
          `atendimentos/evolucoes/?sessao=${this.selectedBudgets[0]}`
        );
        // Filtra os procedimentos onde cobranca é true, o desconto é diferente de 100% e temn assinatura e está ativo a evolucao.
        const filteredData = data.filter(item => item.cobranca === true && item.desconto !== 100 && item.assinatura !== null && item.ativo === true);
        // Ordenando os procedimentos por timestamp em ordem crescente
        this.procedimentos = filteredData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        
        const counter = this.procedimentos.reduce((acc, item) => {
          const id = item.procedimento.procedimento_id;
          if (!acc[id]) {
            acc[id] = { procedimento_id: id, qtd: 0 };
          }
          acc[id].qtd += 1;
          return acc;
        }, {});

        this.qtd = Object.values(counter);
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(
          `Desculpa, aconteceu alguma coisa errada. Erro -> ${error}`
        );
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl;
      const res = await fetch(httpsurl);
      const buffer = await res.blob();
      const url = URL.createObjectURL(buffer);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
  },
}
</script>